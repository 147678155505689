"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _lastIndexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/last-index-of"));

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _Logger = _interopRequireDefault(require("@/libs/Logger"));

var _BouncerProvider = _interopRequireDefault(require("@/libs/BouncerProvider"));

var _Captcha = _interopRequireDefault(require("@/components/Captcha"));

var _CommonLayout = _interopRequireDefault(require("./CommonLayout"));

var log = _Logger.default.namespace('Welcome.vue');

var _default = {
  components: {
    Captcha: _Captcha.default,
    StartupLayout: _CommonLayout.default
  },
  data: function data() {
    return {
      errorMessage: '',
      network: null,
      channel: '',
      nick: '',
      password: '',
      showChannel: true,
      showPass: true,
      toggablePass: true,
      showNick: true,
      show_password_box: false,
      connectWithoutChannel: false,
      showPlainText: false,
      captchaReady: false
    };
  },
  computed: {
    startupOptions: function startupOptions() {
      return this.$state.settings.startupOptions;
    },
    greetingText: function greetingText() {
      var greeting = this.$state.settings.startupOptions.greetingText;
      return typeof greeting === 'string' ? greeting : this.$t('start_greeting');
    },
    footerText: function footerText() {
      var footer = this.$state.settings.startupOptions.footerText;
      return typeof footer === 'string' ? footer : '';
    },
    buttonText: function buttonText() {
      var greeting = this.$state.settings.startupOptions.buttonText;
      return typeof greeting === 'string' ? greeting : this.$t('start_button');
    },
    isNickValid: function isNickValid() {
      var nickPatternStr = this.$state.setting('startupOptions.nick_format');
      var nickPattern = '';

      if (!nickPatternStr) {
        // Nicks cannot start with [0-9- ]
        // ? is not a valid nick character but we allow it as it gets replaced
        // with a number.
        nickPattern = /^[a-z_\\[\]{}^`|][a-z0-9_\-\\[\]{}^`|]*$/i;
      } else {
        // Support custom pattern matches. Eg. only '@example.com' may be allowed
        // on some IRCDs
        var pattern = '';
        var flags = '';

        if (nickPatternStr[0] === '/') {
          // Custom regex
          var pos = (0, _lastIndexOf.default)(nickPatternStr).call(nickPatternStr, '/');
          pattern = nickPatternStr.substring(1, pos);
          flags = nickPatternStr.substr(pos + 1);
        } else {
          // Basic contains rule
          pattern = _lodash.default.escapeRegExp(nickPatternStr);
          flags = 'i';
        }

        try {
          nickPattern = new RegExp(pattern, flags);
        } catch (error) {
          log.error('Nick format error: ' + error.message);
          return false;
        }
      }

      return this.nick.match(nickPattern);
    },
    readyToStart: function readyToStart() {
      var ready = !!this.nick;

      if (!this.connectWithoutChannel && !this.channel) {
        ready = false;
      } // Make sure the channel name starts with a common channel prefix


      if (!this.connectWithoutChannel) {
        var bufferObjs = Misc.extractBuffers(this.channel);
        (0, _forEach.default)(bufferObjs).call(bufferObjs, function (bufferObj) {
          var _context;

          if ((0, _indexOf.default)(_context = '#&').call(_context, bufferObj.name[0]) === -1) {
            ready = false;
          }
        });
      } // If toggling the password is is disabled, assume it is required


      if (!this.toggablePass && !this.password) {
        ready = false;
      }

      if (!this.isNickValid) {
        ready = false;
      }

      return ready;
    }
  },
  watch: {
    show_password_box: function show_password_box(newVal) {
      if (newVal === false) {
        // clear the password when show password is unchecked
        this.password = '';
      }
    }
  },
  created: function created() {
    var _context2;

    var options = this.startupOptions;
    var connectOptions = this.connectOptions(); // Take some settings from a previous network if available

    var previousNet = null;

    if ((0, _trim.default)(_context2 = connectOptions.hostname).call(_context2)) {
      var _context3;

      previousNet = this.$state.getNetworkFromAddress((0, _trim.default)(_context3 = connectOptions.hostname).call(_context3));
    }

    if (previousNet && previousNet.connection.nick) {
      this.nick = previousNet.connection.nick;
    } else if (Misc.queryStringVal('nick')) {
      this.nick = Misc.queryStringVal('nick');
    } else {
      this.nick = options.nick;
    }

    this.nick = this.processNickRandomNumber(this.nick || '');

    if (options.password) {
      this.password = options.password;
    } else if (previousNet && previousNet.password) {
      this.password = previousNet.password;
      this.show_password_box = true;
    } else {
      this.password = '';
    }

    this.channel = decodeURIComponent(window.location.hash) || options.channel || '';
    this.showChannel = typeof options.showChannel === 'boolean' ? options.showChannel : true;
    this.showNick = typeof options.showNick === 'boolean' ? options.showNick : true;
    this.showPass = typeof options.showPassword === 'boolean' ? options.showPassword : true;
    this.toggablePass = typeof options.toggablePassword === 'boolean' ? options.toggablePassword : true;
    this.connectWithoutChannel = !!options.allowNoChannel;

    if (options.bouncer) {
      this.toggablePass = false;
      this.showPass = true;
      this.showChannel = false;
      this.connectWithoutChannel = true;
      var bouncer = new _BouncerProvider.default(this.$state);
      bouncer.enable(connectOptions.hostname, connectOptions.port, connectOptions.tls, connectOptions.direct, connectOptions.direct_path);
    }

    if (options.autoConnect && this.nick && (this.channel || this.connectWithoutChannel)) {
      this.startUp();
    }
  },
  methods: {
    onAltClose: function onAltClose(event) {
      if (event.channel) {
        this.channel = event.channel;
      }

      if (event.nick) {
        this.nick = event.nick;
      }

      if (event.password) {
        this.password = event.password;
      }

      if (event.error) {
        this.errorMessage = event.error;
      }

      this.$state.settings.startupOptions.altComponent = null;
    },
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    formSubmit: function formSubmit() {
      if (this.readyToStart) {
        this.startUp();
      }
    },
    startUp: function startUp() {
      var _this = this;

      this.errorMessage = '';
      var options = (0, _assign.default)({}, this.$state.settings.startupOptions);
      var connectOptions = this.connectOptions();
      var netAddress = (0, _trim.default)(_lodash.default).call(_lodash.default, connectOptions.hostname); // Check if we have this network already

      var net = this.network || this.$state.getNetworkFromAddress(netAddress);
      var password = this.password; // If the network doesn't already exist, add a new one

      net = net || this.$state.addNetwork('Network', this.nick, {
        server: netAddress,
        port: connectOptions.port,
        tls: connectOptions.tls,
        password: password,
        encoding: (0, _trim.default)(_lodash.default).call(_lodash.default, options.encoding),
        direct: connectOptions.direct,
        path: connectOptions.direct_path || '',
        gecos: options.gecos,
        username: options.username
      }); // Clear the server buffer in case it already existed and contains messages relating to
      // the previous connection, such as errors. They are now redundant since this is a
      // new connection.

      net.serverBuffer().clearMessages(); // If we retreived an existing network, update the nick+password with what
      // the user has just put in place

      net.connection.nick = this.nick;

      if (options.bouncer) {
        var _context4;

        // Bouncer mode uses server PASS
        net.connection.password = (0, _concat.default)(_context4 = "".concat(this.nick, ":")).call(_context4, password);
        net.password = '';
      } else {
        net.connection.password = '';
        net.password = password;
      }

      if ((0, _trim.default)(_lodash.default).call(_lodash.default, options.encoding || '')) {
        net.connection.encoding = (0, _trim.default)(_lodash.default).call(_lodash.default, options.encoding);
      }

      this.network = net; // Only switch to the first channel we join if multiple are being joined

      var hasSwitchedActiveBuffer = false;
      var bufferObjs = Misc.extractBuffers(this.channel);
      (0, _forEach.default)(bufferObjs).call(bufferObjs, function (bufferObj) {
        var newBuffer = _this.$state.addBuffer(net.id, bufferObj.name);

        newBuffer.enabled = true;

        if (newBuffer && !hasSwitchedActiveBuffer) {
          _this.$state.setActiveBuffer(net.id, newBuffer.name);

          hasSwitchedActiveBuffer = true;
        }

        if (bufferObj.key) {
          newBuffer.key = bufferObj.key;
        }
      }); // switch to server buffer if no channels are joined

      if (!options.bouncer && !hasSwitchedActiveBuffer) {
        this.$state.setActiveBuffer(net.id, net.serverBuffer().name);
      }

      net.ircClient.connect();

      var onRegistered = function onRegistered() {
        if (_this.$refs.layout) {
          _this.$refs.layout.close();
        }

        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
      };

      var onClosed = function onClosed() {
        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
      };

      net.ircClient.once('registered', onRegistered);
      net.ircClient.once('close', onClosed);
    },
    processNickRandomNumber: function processNickRandomNumber(nick) {
      // Replace ? with a random number
      var tmp = (nick || '').replace(/\?/g, function () {
        return Math.floor(Math.random() * 100).toString();
      });
      return (0, _trim.default)(_lodash.default).call(_lodash.default, tmp);
    },
    handleCaptcha: function handleCaptcha(isReady) {
      this.captchaReady = isReady;
    },
    connectOptions: function connectOptions() {
      var options = (0, _assign.default)({}, this.$state.settings.startupOptions);
      var connectOptions = Misc.connectionInfoFromConfig(options); // If a server isn't specified in the config, set some defaults
      // The webircgateway will have a default network set and will connect
      // there instead. This just removes the requirement of specifying the same
      // irc network address in both the server-side and client side configs

      connectOptions.hostname = connectOptions.hostname || 'default';

      if (!connectOptions.port && connectOptions.direct) {
        connectOptions.port = connectOptions.tls ? 443 : 80;
      } else if (!connectOptions.port && !connectOptions.direct) {
        connectOptions.port = connectOptions.tls ? 6697 : 6667;
      }

      return connectOptions;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["startups"]) window._kiwi_exports["components"]["startups"] = {};
window._kiwi_exports["components"]["startups"]["Welcome"]
window._kiwi_exports.components.startups.Welcome = exports.default ? exports.default : exports;
