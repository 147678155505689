"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));

var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));

var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));

var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));

var _default = {
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      pluginUiSections: _GlobalApi.default.singleton().aboutBufferPlugins,
      closedSections: {}
    };
  },
  computed: {
    b: function b() {
      return this.buffer || {};
    },
    formattedTopic: function formattedTopic() {
      var blocks = (0, _MessageParser.default)(this.b.topic || '', {
        extras: false
      });
      var content = (0, _Html.default)(blocks);
      return content;
    },
    highlights: function highlights() {
      var _context, _context2, _context3, _context4, _context5, _context6;

      // Tap into buffer.message_count to force vuejs to update this function when
      // it changes

      /* eslint-disable no-unused-vars */
      var tmp = this.buffer.message_count;
      return (0, _sort.default)(_context = (0, _filter.default)(_context2 = (0, _filter.default)(_context3 = (0, _filter.default)(_context4 = (0, _filter.default)(_context5 = (0, _filter.default)(_context6 = this.buffer.getMessages()).call(_context6, function (m) {
        return m.isHighlight;
      })).call(_context5, function (m) {
        return m.type !== 'traffic';
      })).call(_context4, function (m) {
        return m.type !== 'topic';
      })).call(_context3, function (m) {
        return m.type !== 'mode';
      })).call(_context2, function (m) {
        return m.html;
      })).call(_context, function (a, b) {
        return b.time - a.time;
      });
    }
  },
  methods: {
    toggleSection: function toggleSection(section) {
      this.$set(this.closedSections, section, !this.closedSections[section]);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SidebarAboutBuffer"]
window._kiwi_exports.components.SidebarAboutBuffer = exports.default ? exports.default : exports;
