"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _lodash = _interopRequireDefault(require("lodash"));

var _ThemeManager = _interopRequireDefault(require("@/libs/ThemeManager"));

var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));

var _localesList = _interopRequireDefault(require("@/res/localesList"));

var _SettingsAliases = _interopRequireDefault(require("./SettingsAliases"));

var _SettingsAdvanced = _interopRequireDefault(require("./SettingsAdvanced"));

/**
 * Returns an object for a vuejs computated property on a state settings value
 * This allows default settings from the server config, but overrides with user config
 */
function bindSetting(settingName) {
  return {
    get: function settingGetter() {
      return this.$state.setting(settingName);
    },
    set: function settingSetter(newVal) {
      this.$state.setting(settingName, newVal);
    }
  };
}

var _default = {
  components: {
    SettingsAliases: _SettingsAliases.default,
    SettingsAdvanced: _SettingsAdvanced.default
  },
  data: function data() {
    return {
      theme: '',
      customThemeUrl: '',
      pluginUiElements: _GlobalApi.default.singleton().appSettingsPlugins,
      localesList: _localesList.default
    };
  },
  computed: {
    themeSupportsMonospace: function themeSupportsMonospace() {
      var themeMgr = _ThemeManager.default.instance();

      var val = themeMgr.themeVar('supports-monospace');
      return val === '1';
    },
    canRegisterProtocolHandler: function canRegisterProtocolHandler() {
      return !!navigator.registerProtocolHandler && this.$state.setting('allowRegisterProtocolHandler');
    },
    timestamps_24h: {
      get: function get24Timestamps() {
        // %H is 24 hour format
        return this.$state.setting('buffers.timestamp_format').substr(0, 2) === '%H';
      },
      set: function set24Timestamps(newVal) {
        var newFormat = newVal ? '%H:%M:%S' : '%l:%M:%S %p';
        this.$state.setting('buffers.timestamp_format', newFormat);
      }
    },
    settings: function getSettings() {
      return this.$state.settings;
    },
    settingShowAutoComplete: bindSetting('showAutocomplete'),
    settingUseMonospace: bindSetting('useMonospace'),
    settingHighlights: bindSetting('highlights'),
    settingBufferColourNicknames: bindSetting('buffers.colour_nicknames_in_messages'),
    settingBufferShowTimestamps: bindSetting('buffers.show_timestamps'),
    settingBufferShowEmoticons: bindSetting('buffers.show_emoticons'),
    settingBufferBlockPms: bindSetting('buffers.block_pms'),
    settingBufferScrollbackSize: bindSetting('buffers.scrollback_size'),
    settingBufferExtraFormatting: bindSetting('buffers.extra_formatting'),
    settingBufferTrafficAsActivity: bindSetting('buffers.traffic_as_activity'),
    settingBufferMuteSound: bindSetting('buffers.mute_sound'),
    settingBufferShareTyping: bindSetting('buffers.share_typing'),
    settingBufferInlineLinkPreviews: bindSetting('buffers.inline_link_auto_previews'),
    settingDefaultBanMask: bindSetting('buffers.default_ban_mask'),
    settingDefaultKickReason: bindSetting('buffers.default_kick_reason'),
    settingAdvancedEnable: {
      get: function getSettingShowAdvancedTab() {
        return this.$state.ui.show_advanced_tab;
      },
      set: function setSettingShowAdvancedTab(newVal) {
        this.$state.ui.show_advanced_tab = newVal;
      }
    },
    settingLanguage: {
      get: function getSettingLanguage() {
        return this.$state.setting('language') || '';
      },
      set: function setSettingLanguage(newVal) {
        this.$state.setting('language', newVal || null);
      }
    },
    messageLayouts: function messageLayouts() {
      return {
        traditional: 'compact',
        modern: 'modern',
        inline: 'inline'
      };
    },
    settingMessageLayout: {
      set: function setSettingMessageLayout(newVal) {
        var l = this.messageLayouts;
        this.$state.setting('buffers.messageLayout', l[newVal] || l.modern);
      },
      get: function get() {
        var s = this.$state.setting('buffers.messageLayout');

        var l = _lodash.default.invert(this.messageLayouts);

        return l[s];
      }
    }
  },
  created: function created() {
    this.listenForThemeSettings();
  },
  methods: {
    closeSettings: function closeSettings() {
      this.$state.$emit('active.component');
    },
    refreshTheme: function refreshTheme() {
      _ThemeManager.default.instance().reload();
    },
    listenForThemeSettings: function listenForThemeSettings() {
      var _this = this;

      var themeMgr = _ThemeManager.default.instance();

      var watches = []; // Called when the current theme changes (including url refreshes)

      var updateFn = function updateFn() {
        var theme = themeMgr.currentTheme();
        _this.theme = theme.name;
        _this.customThemeUrl = theme.name === 'custom' ? theme.url : '';
      };

      var watchTheme = function watchTheme(newVal) {
        themeMgr.setTheme(newVal);
      };

      var watchCustomThemeUrl = function watchCustomThemeUrl(newVal) {
        if (themeMgr.currentTheme().name === 'custom') {
          themeMgr.setCustomThemeUrl(newVal);
        }
      }; // Remove all our attached events to cleanup


      var teardownFn = function teardownFn() {
        _this.$state.$off('theme.change', updateFn);

        (0, _forEach.default)(watches).call(watches, function (unwatchFn) {
          return unwatchFn();
        });

        _this.$off('hook:destroy', teardownFn);
      }; // Update our info with the latest theme settings before we start
      // listening for changes


      updateFn();
      this.$state.$on('theme.change', updateFn);
      this.$once('hook:destroyed', teardownFn); // $watch returns a function to stop watching the data field. Add them into
      // an array to make it easier to iterate over them all and unwatch them all
      // when needed.

      watches = [this.$watch('theme', watchTheme), this.$watch('customThemeUrl', watchCustomThemeUrl)];
    },
    enableAdvancedTab: function enableAdvancedTab() {
      var _this2 = this;

      this.settingAdvancedEnable = true;
      this.$nextTick(function () {
        _this2.$refs.tabs.setActiveByName('advanced');

        _this2.$el.scrollTop = 0;
      });
    },
    makeDefaultProtocolHandler: function makeDefaultProtocolHandler() {
      navigator.registerProtocolHandler('irc', document.location.origin + document.location.pathname + '#%s', 'Kiwi IRC');
      navigator.registerProtocolHandler('ircs', document.location.origin + document.location.pathname + '#%s', 'Kiwi IRC');
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["AppSettings"]
window._kiwi_exports.components.AppSettings = exports.default ? exports.default : exports;
